import React, { useState } from 'react';
import { MenuItem, Box, Button, Menu } from '@mui/material';
import TickButton from '../../assests/icons/check-button.png'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { updateEapxCase } from '../../../utils/ApiClient';
import useGlobalState from '../../../context/useGlobalState';
import { isProviderApproved } from '../../../utils/helperFunctions';
import { caseStatusChangeDialog, storageKeys, userType, SideBarTabItemType1, SideBarTabItemType2, PRTUserCasesType, CaseStatusType, CaseStatus, EAPXCaseStatusEnum } from '../../../utils/constants';
import ConfirmationDialogBox from '../DialogBox/ConfirmDialogBox';
import './DropdownWithConfirmationBar.scss'
import { ProvidersType } from '../../pages/prt-dashboard/PRTDashboardHome';

interface CustomSelectWithButtonsProps {
  options: {} | [];
  name: string;
  stateChange: (state: string) => void;
  state: any;
  showButtons: boolean;
  fileNo: number | undefined;
  showPopUp: boolean;
  dialogTitle?: string;
  onConfirm?: () => void;
  confirmLabel?: string;
  activeCaseUUID?: string;
  providers?: ProvidersType;
  setSuccessMessage?: (message: string) => void;
  setCaseStatus?: (state: string) => void;
  casesTypeName: string;
  caseStatus?: string;
  specialist?: string;
  setError?: (message: string) => void;
  isOldCase?:boolean;
  width?: string;
}

const DropdownWithConfirmationBar: React.FC<CustomSelectWithButtonsProps> = (props) => {
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false)
  const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
  const isManager = userTypeName === userType.PRT_MANAGER
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isMenuDisabled = (props.casesTypeName === "Closed" && props.name !== "eapxFileStatus") || isManager ? true : false
  const [valueToSend, setValueToSend] = useState<string>("")
  const { globalErrorHandler } = useGlobalState()
  const [isDialogButtonDisabled, setIsDialogButtonDisabled] = useState<boolean>(false)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelButtonPressed = () => {
    if (props.name === "eapx_file_status") {
      if (props.caseStatus) {
        props.stateChange(props.caseStatus)
        if (props.setCaseStatus) {
          props.setCaseStatus(props.caseStatus)
        }
      }
      setOpenDialogBox(false)
      setAnchorEl(null)
    } else {
      props.stateChange(props.specialist ?? "")
      setAnchorEl(null)
    }
  };

  const updateCase = async (option: string): Promise<void> => {
    const data = { [props.name === "Assigned Specialist" ? "assigned_specialist" : props.name]: option }
    setIsDialogButtonDisabled(true)
    try {
      const response = await updateEapxCase(props.fileNo ?? 0, data)
      if (props.setCaseStatus) {
        props.setCaseStatus(props.state)
      }
      if (props.setSuccessMessage) {
        props.setSuccessMessage(response.data.message);
      }
      setAnchorEl(null);
      setIsDialogButtonDisabled(false)
      setOpenDialogBox(false)
    } catch (error) {
      if (props.caseStatus && props.name === "eapxFileStatus") {
        props.stateChange(props.caseStatus)
      } else {
        props.stateChange("")
      }
      props.stateChange("")
      setIsDialogButtonDisabled(false)
      globalErrorHandler(error)
      setAnchorEl(null);
    }
  }

  const handleSave = async (state: string): Promise<void> => {
    if (props.name === "eapxFileStatus" && state === EAPXCaseStatusEnum.ACCEPTED) {
      const result = isProviderApproved(props.providers, props.activeCaseUUID ?? "")
      if (!result) {
        if (props.showPopUp) {
          setOpenDialogBox(true)
        }
      } else {
        updateCase(state)
      }
    } else {
      if (!state && !props.specialist) {
        props.setError?.("Please select Assign specialist")
        handleClose()
      }
      else updateCase(state)
    }
  };

  const handleMenuItemClick = (value: any) => {
    if (value.fullName) {
      setValueToSend(value.id)
      props.stateChange(value.fullName)
    } else {
      if (value.label) {
        setValueToSend(value.value)
      } else {
        setValueToSend(value)
      }
      props.stateChange(value.label ? value.label : value);
    }
  };

  return (
    <Box sx={{ textAlign: 'end' }}>
      <Button
        aria-controls="assigned-specialist-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
        width: props?.width,
        borderRadius: props.casesTypeName === PRTUserCasesType.CLOSED.label ? '7px':'',
        height:"42px",
        justifyContent: 'space-between',
        textAlign: 'left',
        backgroundColor: 'white',
        color: '#000000',
        }}
        sx={{ textTransform: 'none' }}
        variant='contained'
        color='info'
        endIcon={(props.isOldCase || props.casesTypeName === PRTUserCasesType.CLOSED.label) ? null : <ArrowDropDownIcon />}
      >
        {props.state || props.name}
      </Button>
      {!props.isOldCase && <Menu
        id="assigned-specialist-menu"
        anchorEl={anchorEl}
        open={isMenuDisabled ? false : Boolean(anchorEl)}
        onClose={handleClose}
        defaultValue={props.state}
        MenuListProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : '100',
            paddingBottom: '0px'
          }
        }}
      >
        {props.options &&
          Object.values(props.options)
            .filter((item: any) => !(props.casesTypeName === PRTUserCasesType.CLOSED.label && item?.value === EAPXCaseStatusEnum.RE_OPENED))
            .map(
              (
                item: SideBarTabItemType1 | SideBarTabItemType2 | CaseStatusType,
                index: number
              ) => {
                const isEapxFileStatus =
                  props.name === "eapxFileStatus" &&
                  props.casesTypeName === PRTUserCasesType.MY_CASES.label &&
                  index === 3;
                const disableFirstThreeForEapxFileStatus =
                  props.name === "eapxFileStatus" &&
                  props.casesTypeName === PRTUserCasesType.CLOSED.label &&
                  index < 3;

                let displayLabel: string;
                if (typeof item === "string") {
                  displayLabel = item;
                } else if ("label" in item) {
                  displayLabel = item.label;
                } else {
                  displayLabel = item.fullName;
                }
                const isSelected = props.state === displayLabel;
                return (
                  <MenuItem
                    key={index}
                    onClick={() => handleMenuItemClick(item)}
                    disabled={isEapxFileStatus || disableFirstThreeForEapxFileStatus}
                    style={isSelected ? { fontWeight: "600" } : {}}
                  >
                    {displayLabel}
                    {isSelected && (
                      <img src={TickButton} alt="checked" style={{ marginLeft: "auto" }} />
                    )}
                  </MenuItem>
                );
              }
            )
        }

        <Box>
          {
            props.showButtons && <div className={'button-area'}>
              <button onClick={handleCancelButtonPressed} className='cancel-btn'>
                {"Cancel"}
              </button>
              <button onClick={() => handleSave(valueToSend)} className={isDialogButtonDisabled ? 'processing-btn' : 'save-btn'} disabled={isDialogButtonDisabled}>
                {"Save"}
              </button>
            </div>
          }
        </Box>
        <ConfirmationDialogBox
          title={caseStatusChangeDialog.title}
          content={caseStatusChangeDialog.content}
          onConfirm={() => updateCase(valueToSend)}
          handleClose={handleCancelButtonPressed}
          confirmLabel={"Confirm"}
          openDialogBox={openDialogBox}
          buttonDisabled={isDialogButtonDisabled} />
      </Menu>}
    </Box>
  );
};

export default DropdownWithConfirmationBar;
