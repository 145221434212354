import React from 'react'
import { MenuItem } from '@mui/material'
import './CaseList.scss'
import { storageKeys, userType } from '../../../../utils/constants'
import { ApiEAPXCase } from '../../../../interfaces/ApiTypes'

interface CaseListProps {
    caseList: ApiEAPXCase[];
    noCase: boolean;
    searchTerm: string;
    casesTypeName: string;
    onCaseSelect?: (fileNo: number, id?: string, status?:string, modifiedDays?:number) => void;
    handleClaimCase?:(fileNo:number) => void
}

const CaseList: React.FC<CaseListProps> = (props) => {
    const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
    const isManager = userTypeName !== userType.PRT_USER

    const onClaimCase = async (fileNo: number): Promise<void> => {
        if(props.handleClaimCase){
           props.handleClaimCase(fileNo)
        }
    }
    
    const handleSelectedCase = (fileNo: number, id?:string , status?:string, modifiedDays?:number, claimedByCurrentUser?:boolean) => { 
        if (!isManager && !claimedByCurrentUser) {
            return;
          }
        if (props.onCaseSelect) {
           props.onCaseSelect(fileNo,id,status,modifiedDays);
        }
    }

    return (
        <div className='case-list-component'>
            {
               props.noCase ? <MenuItem><span className='no-cases menu-items'>"#{props?.searchTerm}" {"Case not found"}</span></MenuItem> : <>
                    {
                       props.caseList?.map((item, index) => {
                           const isClosedByAnotherUser = !isManager && item.isCaseClosedByAnotherUser;
                           const isClaimedByAnotherUser:boolean = !isManager && item.isClaimed && !item.claimedByCurrentUser; 
                           const isUserClaimedCase = item?.claimedByCurrentUser && item.isClaimed;
                           return (
                                <MenuItem key={"case" + index} className='menu-items' onClick={() => handleSelectedCase(item.eapxFileNumber, undefined ,item.eapxFileStatus, item.eapxLastModifiedDays, item.claimedByCurrentUser)}>
                                    <div className='info-col'>
                                        <div className='file-info'>
                                            <span className='file-name'>#{item.eapxFileNumber}</span>
                                            <span className='last-modify-date'>{item.companyName}</span>
                                        </div>
                                        <div>
                                        {(isClosedByAnotherUser && isClaimedByAnotherUser) && (<span className="claimed-status">Closed by {item.assignedPrtUser}</span>)}
                                        {(isClaimedByAnotherUser && !isClosedByAnotherUser) && (<span className='claimed-status'>Claimed by {item.assignedPrtUser}</span>)}
                                        </div>
                                    </div>
                                    {!isManager && (isUserClaimedCase ?(
                                    <button
                                        className={'claim-button'}
                                        onClick={() => handleSelectedCase(item.eapxFileNumber, undefined ,item.eapxFileStatus, item.eapxLastModifiedDays, item.claimedByCurrentUser)}
                                    >
                                        View
                                    </button>):(!item.isClaimed)&& <button className={'claim-button'}
                                            onClick={() => onClaimCase(item.eapxFileNumber)}>
                                             Claim
                                        </button>)
                                    }
                                </MenuItem>)
                        })
                    }</>
            }
        </div>
    )
}

export default CaseList