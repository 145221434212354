import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { common } from "../../../utils/constants";
import "./Loader.scss";

const Loader: React.FC = () => {
  return (
    <div className="loader-component">
      <Box sx={{ display: 'flex' }}>
        <div className="loader-component">
          <CircularProgress />
          <span>{common.LOADING}</span>
        </div>
      </Box>
    </div>
  );
};

export default Loader;
