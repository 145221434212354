import moment from "moment";
import { formatRelative, parseISO } from "date-fns";
import { GridColDef } from "@mui/x-data-grid";
import EnvironmentConfig from "../component/config/EnvironmentConfig";
import { storageKeys } from "./constants";

//Function to calcualte age of User
export function calculateAge(birthdate: string): number {
  const birthDate = moment(birthdate, "DD-MM-YYYY");
  const age = moment().diff(birthDate, "years");
  return age;
}

//Function to get date of tickets
export function getFormattedDate(date: Date | string) {
  const momentDate = moment(date);
  const month = momentDate.format("MMMM");
  const day = momentDate.format("DD");
  const year = momentDate.year();
  return `${month}, ${day} ${year}`;
}

export function formateDate(timestamp: any) {
  const date = new Date(timestamp).toISOString();
  const formattedRelativeTime = formatRelative(parseISO(date), new Date(), {
    weekStartsOn: 1,
  });
  return formattedRelativeTime;
}

//Function to get Queryparameters for redirection-url
export function getQueryParams() {
  const firstName: string | null =
    localStorage.getItem(storageKeys.FIRST_NAME) || "";
  const lastName: string | null =
    localStorage.getItem(storageKeys.LAST_NAME) || "";
  const email: string | null = localStorage.getItem(storageKeys.EMAIL) || "";
  const helpForm: string | null =
    localStorage.getItem(storageKeys.HELP_FORM) || "";
  const company: string | null =
    localStorage.getItem(storageKeys.COMPANY) || "";
  const parsedData = helpForm ? JSON.parse(helpForm) : "";
  const phone = parsedData?.phone_number ? parsedData.phone_number : "";
  const queryParams = `?firstName=${encodeURIComponent(
    firstName
  )}&lastName=${encodeURIComponent(lastName)}&email=${encodeURIComponent(
    email
  )}&phone=${encodeURIComponent(phone)}&${EnvironmentConfig.companyNameField
    }=${encodeURIComponent(company)}`;

  return queryParams;
}

export function transformString(str: string) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
    .join("-");
}

export function getSimplifiedUserName(input: string): string {
  const parts: string[] = input.split("_");
  const filteredParts: string[] = parts.filter((part) => (Number(part)));
  const capitalizedParts: string[] = filteredParts.map((part) => {
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });
  return capitalizedParts.join(" ");
}

//Function for eap-case-file to extractTableData from html-string
export const extractTableDataFromString = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const rows = doc.querySelectorAll('table tr');
  const rowsData: any = [];

  rows.forEach(row => {
    const cells = row.querySelectorAll('td');
    if (cells.length > 0) {
      const rowData: any = [];
      cells.forEach(cell => {
        rowData.push(cell.textContent);
      });
      rowsData.push(rowData);
    }
  });
  return rowsData
};

export const getDateObjectForCustomDates = (option: string) => {
  const dateObject = {
    fromDate: "",
    toDate: moment().format('YYYY-MM-DD'),
  }
  if (option === "Last 24 hour") {
    dateObject.fromDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
  } else if (option === "Last week") {
    dateObject.fromDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
  } else if (option === "Last month") {
    dateObject.fromDate = moment().subtract(1, 'month').format('YYYY-MM-DD')
  } else if (option === "Last 3 month") {
    dateObject.fromDate = moment().subtract(3, 'months').format('YYYY-MM-DD')
  }
  return dateObject
}

// Combined function to create column definitions with formatted header names
export const dataGridTableColumn = (
  field: string,
  headerName: string,
  minWidth = 150,
  maxWidth = 300
): GridColDef => {
  const formattedHeaderName = headerName
    .replace(/_/g, " ")
    .replace(/(?:^|\s)\S/g, (a: any) => a.toUpperCase());
  return {
    field,
    headerName: formattedHeaderName,
    minWidth,
    maxWidth,
  };
};

export function getFormatDateString(dateString: number | null | string): { formattedDate: string, age: number } {
  const date = new Date(dateString ?? "");
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${month}-${day}-${year}`;
  const now = new Date();
  let age = now.getFullYear() - date.getFullYear();
  const monthDifference = now.getMonth() - date.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && now.getDate() < date.getDate())) {
    age--;
  }
  return { formattedDate, age };
}

interface QuestionObject {
  question: string;
  answer: string;
}

export function filterCustomQuestions(arr: string[][]): { [key: string]: string } {
  const pattern = /Custom Question \d+:/i;

  const questionObjects = arr
    .map(subArray => {
      const foundQuestion = subArray.find(item => pattern.test(item)) || "";
      const question = foundQuestion.replace(/:$/, "").toLowerCase().replace(/ /g, '_').replace(/[^a-z0-9_]/g, '');
      if (question) {
        let answer = subArray.filter(item => !pattern.test(item)).join(", ");
        return { question, answer };
      }
      return null;
    })
    .filter((item): item is QuestionObject => item !== null);
  return questionObjects.reduce((acc, { question, answer }) => {
    acc[question] = answer;
    return acc;
  }, {} as { [key: string]: string });
}

export function isProviderApproved(caseData: any, caseId: number | string) {
  if (caseData && Array.isArray(caseData)) {
    return caseData.some((item: any) => item.providerStatus === 'provider_approved');
  }
  else {
    return false;
  }
}

export function validateRequiredFields(obj: any): { valid: boolean; message?: string } {
  const value = obj?.notes?.toString().trim();
  if (!value || value.length < 1) {
    return {
      valid: false,
      message: 'The Notes is required.',
    };
  }
  return { valid: true };
}

export function extractTimeFromString(dateString: number | null | string): string {
  const date = new Date(dateString ?? "");
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export const getAppointString = (data: any): string => {
  if (!data) {
    return "-";
  }
  if (typeof data === 'string') {
    return data;
  } else {
    const formattedString = Object.entries(data)
      .map(([key, value]) => {
        if (key === "appointmentDatePreference" && typeof value === 'string') {
          return `${key}: ${value}`;
        }
        return `${key}: ${Array.isArray(value) ? value.join(', ') : value}`;
      })
      .join(', ');
    return formattedString.length > 0 ? formattedString : "-";
  }
};



export function extractDateTime(input: string): string {
  const dateObj = new Date(input);
  if (Number.isNaN(dateObj.getTime())) {
    return 'Invalid date format';
  }
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const formattedDate = `${month}/${day}/${year}`;
  const formattedTime = `${hours}:${minutes}`;
  return `${formattedDate}; ${formattedTime}`;
}