import React, { useState, useEffect, useRef } from 'react'
import EAPCaseSideBar from '../../common/SideTabs/eap-cases-sidebar/EAPCaseSideBar'
import { assignProvider, getEAPCaseFileData, getEAPCases, reOpenEapxCase } from '../../../utils/ApiClient'
import Loader from '../../common/Loader/Loader'
import Providers from './Providers'
import './PRTDashboardHome.scss'
import CaseDetails from './CaseDetails'
import { CaseTypeEnum, PRTUserCasesType, ProviderStatus } from '../../../utils/constants'
import NoteSection from './NoteSection'
import { ApiEAPXCase, ApiPRTCaseDetails } from '../../../interfaces/ApiTypes'
import useGlobalState from '../../../context/useGlobalState'
import { extractDateTime } from '../../../utils/helperFunctions'
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export type Provider = {
    eapx_case: string;
    eapx_last_modified_days?:number;
    eapx_file_number: number | undefined;
    id: number;
    provider_detail: string;
    status?: string | undefined;
    notes: string;
    assignedDate: string | null;
    providerStatus: string | { label: string; value: string; } | null | undefined;
    providerStatusDisplay: string | { label: string; value: string; } | null | undefined;
    file_number?: undefined | number
    activeCaseInfo?:ApiPRTCaseDetails | undefined;
    setActiveCaseInfo?: (caseInfo: any) => void | undefined;
    setCaseStatus?: (state: string) => void;
};

export type ProvidersType =  Provider[] 

const PRTDashboardHome: React.FC = () => {
    const [activeCaseUUID, setActiveCaseUUID] = useState<string>("")
    const [cases, setCases] = useState<ApiEAPXCase[]>([])
    const [caseStatus, setCaseStatus] = useState<string>("")
    const [activeCaseInfo, setActiveCaseInfo] = useState<ApiPRTCaseDetails>()
    const [casesTypeName, setCasesTypeName] = useState<string>("My Cases")
    const [loading, setLoading] = useState<boolean>(true)
    const [caseLoading, setCaseLoading] = useState<boolean>(false)
    const [providers, setProviders] = useState<ProvidersType>([]);
    const [noData, setNoData] = useState(false)
    const [specialist, setSpecialist] = useState("")
    const [caseClaimed, setCaseClaimed] = useState<boolean>(false)
    const { globalErrorHandler } = useGlobalState()
    const [notes, setNotes] = useState<string>("")
    const [emptyNotes, setEmptyNotes] = useState<boolean>(false)
    const showButton = (casesTypeName !== PRTUserCasesType.CLOSED.label)
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [caseReopen, setCaseReopen] = useState<boolean>(false)
    const [loader,setLoader] = useState(false)
    const [closingCase, setClosingCase] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const isOldCase = activeCaseInfo && activeCaseInfo.eapxLastModifiedDays >= 60;
    const showAddProviderButton = showButton && activeCaseInfo && activeCaseInfo.eapxLastModifiedDays <= 60;
    const effectRef = useRef(false);
    const type = PRTUserCasesType.MY_CASES.label ? "all" : "closed"
    
    const addProvider = () => {
        if (activeCaseUUID) {
            const newProvider: Provider = {
                id: 0,
                provider_detail: '',
                status: '',
                notes: '',
                eapx_case: activeCaseUUID,
                eapx_file_number: activeCaseInfo?.fileNumber,
                assignedDate: null,
                providerStatus: ProviderStatus.REQUEST_SENT.value,
                providerStatusDisplay: ProviderStatus.REQUEST_SENT.label
            };
            setProviders((prevState) => [...prevState, newProvider]);
        }
    };

   const caseData = (data: any) => {
        if (data !== null || data !== undefined) {
            setSpecialist(data?.assignedSpecialist)
            setCaseStatus(data?.eapxFileStatusDisplay)
            setNotes(data?.notes)
        }
    }

    const getCaseData = async (id: number): Promise<void> => {
        setLoading(false)
        setCaseLoading(true);
        if (caseReopen) { setCaseReopen(false) }
        try {
            const response = await getEAPCaseFileData(id);
            setActiveCaseInfo(response.data.data);
            setActiveCaseUUID(response.data.data.id)
            setCaseLoading(false);
            setLoading(false)
        } catch (error) {
            setCaseLoading(false);
            globalErrorHandler(error);
        }
    };

    const getEapCaseData = async (caseType: string): Promise<void> => {
        try {
            const response = await getEAPCases(caseType)
            if (!response.data.data.cases.length) {
                setNoData(true)
                setCases([])
            }
            else {
                setCases(response.data.data.cases);
                setNoData(false)
            }
            if (caseReopen) {
                getCaseData(activeCaseInfo?.fileNumber ?? 0)
            } else {
                setLoading(false)
                setCaseLoading(false)
                setCaseReopen(false)
            }

        } catch (error) {
            globalErrorHandler(error)
            setLoading(false)
            setCaseLoading(false)
        }
    }

    useEffect(() => {
        if (effectRef.current === false) {
            getEapCaseData(type)
        }
        return () => { effectRef.current = true }
    }, [])

    useEffect(() => {
        if (caseClaimed || caseReopen) {
            getEapCaseData(type)
        }
    }, [caseClaimed, caseReopen])

    useEffect(() => {
        if (activeCaseInfo) {
            setProviders(
                activeCaseInfo.providerOutreach.map((value: any) => ({ ...value, status: "Save" }))
            );
        }
    }, [activeCaseInfo]);
    
    useEffect(() => {
        if (successMessage) {
            setTimeout(() => {
                setNotes(notes)
                setSuccessMessage("")
            }, 5000)
        }
    }, [successMessage])

    useEffect(() => {
        if (emptyNotes) {
            setTimeout(() => {
                setEmptyNotes(false)
            }, 5000)
        }
    }, [emptyNotes])

    useEffect(() => {
        if (cases?.length) {
            caseData(activeCaseInfo)
        }
    }, [activeCaseUUID, activeCaseInfo, cases, casesTypeName])
    
    const handleReopenCase = async (fileNo: number): Promise<void> => {
        if (casesTypeName === PRTUserCasesType.CLOSED.label) {
            setLoader(true); 
            try {
                await reOpenEapxCase(fileNo); 
                setLoading(false)
                setCasesTypeName(PRTUserCasesType.MY_CASES.label); 
                setCaseLoading(true); 
                if (setCaseReopen) {
                    setCaseReopen(true); 
                }
            } catch (error) {
                globalErrorHandler(error);
            } finally {
                setLoader(false); 
                setCaseLoading(false)
            }
        }
    };

    const handleCaseClosed = async () => {
        try {
            setClosingCase(true);
            await getCaseData(activeCaseInfo?.fileNumber ?? 0);
            getEapCaseData(CaseTypeEnum.CLOSED);
            setCasesTypeName(PRTUserCasesType.CLOSED.label);
        } catch (error) {
            globalErrorHandler(error);
        } finally {
            setClosingCase(false);
        }
    };
    
    const assignProviderOutreach = async (providerId: number): Promise<void> => {
        setClosingCase(true);
        try {
            const response = await assignProvider(providerId);
            const dateAndTime = extractDateTime(response.data.data.assignedDate);
            setSuccessMessage(`PROVIDER ASSIGNED - ${dateAndTime}`);
            await handleCaseClosed()
        } catch (error) {
            globalErrorHandler(error);
        } finally {
            setClosingCase(false);
        }
    };

    const handleCaseTypeSelected = async (case_type: any) => {
        if (casesTypeName === case_type.name) return
        setSearchTerm("");
        setActiveCaseUUID("");
        try {
          setLoading(true);
          getEapCaseData(case_type.value);
          setCasesTypeName(case_type.label);
        } catch (error) {
          globalErrorHandler(error);
        } finally {
          setLoader(false);
          setCaseLoading(false);
        }
    };
      
    return (
        <div className='eap-cases-page'>
            {
                loading ? <div className='loader-component-section'> <Loader /></div> :
                    <div className='case-info-section'>
                        <EAPCaseSideBar
                            tabs={PRTUserCasesType}
                            cases={cases}
                            setActiveCaseInfo={setActiveCaseInfo}
                            casesTypeName={casesTypeName}
                            setCasesTypeName={setCasesTypeName}
                            activeCaseUUID={activeCaseUUID}
                            setActiveCaseUUID={setActiveCaseUUID}
                            setCaseLoading={setCaseLoading}
                            setCaseClaimed={setCaseClaimed}
                            caseStatus={caseStatus}
                            caseLoading={caseLoading}
                            setNoData={setNoData}
                            setCaseReopen={setCaseReopen}
                            setSearchTerm={setSearchTerm}
                            searchTerm={searchTerm}
                            onCaseSelected={handleCaseTypeSelected} 
                            onCaseTypeTabChange={getEapCaseData}
                            setLoading={setLoading}/>
                        {
                            caseLoading ? <div className='case-content'>
                                <Loader />
                            </div> : <div className='case-details-section'>
                                {
                                    noData ? <span className='no-data-section'>{"No cases claimed"}</span> :activeCaseUUID === "" ?<span className='no-data-section'>Please select a case from the left column</span>:
                                        <div className='case-content'>
                                            <CaseDetails
                                                activeCaseInfo={activeCaseInfo}
                                                caseStatus={caseStatus}
                                                caseLoading={caseLoading}
                                                setCaseLoading={setCaseLoading}
                                                setCaseStatus={setCaseStatus}
                                                providers={providers}
                                                specialist={specialist}
                                                casesTypeName={casesTypeName}
                                                setCasesTypeName={setCasesTypeName}
                                                setCaseReopen={setCaseReopen} 
                                                loader={loader}
                                                onCaseReOpen={handleReopenCase} />
                                            <div className='case-sub-section'>
                                                {providers?.map((provider: any, index) => (
                                                  <Providers
                                                  key={index + 2}
                                                  providerNo={index}
                                                  provider={provider}
                                                  activeCaseUUID={activeCaseUUID}
                                                  setProviders={setProviders}
                                                  providers={providers}
                                                  caseStatus={caseStatus}
                                                  setCasesTypeName={setCasesTypeName}
                                                  setNoData={setNoData}
                                                  setCases={setCases}
                                                  casesTypeName={casesTypeName}
                                                  onAssignProvider={assignProviderOutreach}
                                                  successMessage={successMessage}
                                                  isOldCase={isOldCase}
                                              />
                                                ))}
                                                {
                                                   showAddProviderButton && <button className='add-button' onClick={addProvider}>{" + Add additional Provider"}</button>
                                                }
                                            </div>
                                            <NoteSection
                                                notes={notes}
                                                setNotes={setNotes}
                                                casesTypeName={casesTypeName}
                                                fileNo={activeCaseInfo?.fileNumber}
                                                setSuccessMessage={setSuccessMessage}
                                                isOldCase={isOldCase} />
                                            {successMessage && <span className='success-message'>{successMessage}</span>}
                                        </div>
                                }
                            </div>
                        }
                    </div>
            }
            <Backdrop open={closingCase} >
                <div className='backdrop-loader'>
                    <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.12)' }} />
                    <span>{"CLOSING CASE"}</span>
                </div>
            </Backdrop>
        </div>
    )
}

export default PRTDashboardHome 