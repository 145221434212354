import { common, storageKeys } from "./constants";

interface FormData {
  company: string;
  member_type: string;
  gender: string;
  marital_status: string;
  phone: string;
  leave_mail: string;
  street_address: string;
  apt_floor: string;
  city: string;
  state: string;
  zipcode: string;
}

interface LoginResponse {
  data: {
    data: {
      token: string;
      username: string;
      email: string;
      firstName: string;
      lastName: string;
      sessionToken: string;
      sessionTokenExpirationTime: string;
      organizationId: string;
      organizationName: string;
      userType: string;
      id: any;
    };
  };
}

interface TicketResponse {
  data: {
    data: {
      id: number;
      channelName: string;
      channelUrl: string;
    };
  };
}

class CredentialStorage {
  // Storing values of help-page form
  formValues(formData: FormData): void {
    const phoneWithoutDashes = formData.phone.replace(/-/g, "");
    localStorage.setItem(
      storageKeys.HELP_FORM,
      JSON.stringify({
        company: formData.company,
        clientType: formData.member_type,
        gender: formData.gender,
        marital_status: formData.marital_status,
        phone_number: phoneWithoutDashes,
        leave_voicemail: formData.leave_mail,
        street_address: formData.street_address,
        apt_floor: formData.apt_floor,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zipcode,
      })
    );
  }

  // Storing login credential after successful login/registration
  login(response: LoginResponse): void {
    const { data } = response.data;
    localStorage.setItem(storageKeys.TOKEN, data.token);
    localStorage.setItem(storageKeys.USER_ID, data.username);
    localStorage.setItem(storageKeys.EMAIL, data.email);
    localStorage.setItem(storageKeys.FIRST_NAME, data.firstName);
    localStorage.setItem(storageKeys.LAST_NAME, data.lastName);
    localStorage.setItem(storageKeys.SESSION_TOKEN, data.sessionToken);
    localStorage.setItem(storageKeys.COMPANY, data.organizationName);
    localStorage.setItem(storageKeys.ORGANIZATION_ID, data.organizationId);
    localStorage.setItem(
      storageKeys.SESSION_EXPIRY,
      data.sessionTokenExpirationTime
    );
    localStorage.setItem(storageKeys.USER_TYPE, data.userType);
    localStorage.setItem(storageKeys.ID, data.id);
  }

  // Storing ticket info and channel info after create-ticket
  ticketInfo(response: TicketResponse): void {
    const { data } = response.data;
    const ticketId = data.id.toString();
    localStorage.setItem(storageKeys.TICKET_ID, ticketId);
    localStorage.setItem(storageKeys.CHANNEL_NAME, data.channelName);
    localStorage.setItem(storageKeys.CHANNEL_URL, data.channelUrl);
  }

  eapxValues() {
    let flow = localStorage.getItem(storageKeys.FLOW);
    let formData: any = localStorage.getItem(storageKeys.HELP_FORM);
    let parsedData: any = JSON.parse(formData);
    let email: any = localStorage.getItem(storageKeys.EMAIL);
    let firstName: any = localStorage.getItem(storageKeys.FIRST_NAME);
    let lastName: any = localStorage.getItem(storageKeys.LAST_NAME);
    let dob: any = localStorage.getItem(storageKeys.BIRTH_DATE);
    let question_1: any =
      flow === common.SELF_SCHEDULING
        ? localStorage.getItem(storageKeys.SELF_SECHULE_QUESTION)
        : localStorage.getItem(storageKeys.TEXT_COACHNG_QUESTION);
    let age: any = localStorage.getItem(storageKeys.AGE);

    return {
      company: parsedData?.company,
      email: email,
      first_name: firstName,
      last_name: lastName,
      dob: dob,
      age: age,
      gender: parsedData?.gender,
      phone_number: parsedData?.phone_number,
      street_address: parsedData?.street_address,
      state: parsedData?.state,
      zip_code: parsedData?.zip_code,
      marital_status: parsedData?.marital_status,
      clientType: parsedData?.clientType,
      question_1: question_1 ? question_1 : "No value",
      leave_voicemail: parsedData?.leave_voicemail,
      city: parsedData?.city,
    };
  }
}

const instance = new CredentialStorage();

export default instance;
