import { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { activePRTCoordinator } from '../../../../utils/ApiClient'
import CredentialStorage from "../../../../utils/CredentialStorage";
import logo from "../../../assests/icons/logo.png";
import Loader from "../../../common/Loader/Loader";


interface ActivationStatus {
  success: boolean;
  message: string;
}

const ActivateCoordinator = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [verificationStatus, setVerificationStatus] = useState<ActivationStatus>({ success: false, message: "" });
  const effectRef = useRef(false);
  const { user_id, magic_token } = useParams<{
    user_id: string;
    magic_token: string;
  }>();
  const navigate = useNavigate()

  const activateCoordinator = async (user_id: string, magic_token: string): Promise<void> => {
    try {
      const response = await activePRTCoordinator(user_id, magic_token);
      CredentialStorage.login(response);
      setLoading(false);
      navigate('/dashboard')
    } catch (error: any) {
      setVerificationStatus({
        success: false,
        message: error.response?.data.message,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (effectRef.current === false) {
      if (user_id && magic_token) {
        activateCoordinator(user_id, magic_token);
      }
    }
    return () => {
      effectRef.current = true;
    };
  }, [user_id, magic_token]);

  return (
    <div className="magic-token-varification-page">
      <div className="fixed-header-layout">
        <header className="fixed-header">
          <div className="header">
            <img
              src={logo}
              alt="logo"
              className="fixed-header-logo"
            />
          </div>
        </header>
        <div className="content">
          <div className="page-content">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {verificationStatus.success ? (
                  <div className="success">{verificationStatus.message}</div>
                ) : (
                  <div className="error">{verificationStatus.message}</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivateCoordinator