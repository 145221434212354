import React, { useState, useEffect, useRef } from 'react'
import { getFormatDateString, getAppointString } from '../../../utils/helperFunctions'
import { PRTUserCasesType, storageKeys, userType, unAssignDialog, PRTManagerCaseType, EAPXCaseStatusEnum } from '../../../utils/constants';
import CustomDropdownMenu from '../../common/DropdownMenu/DropdownWithConfirmationBar';
import ConfirmDialogBox from '../../common/DialogBox/ConfirmDialogBox';
import { getPRTSpecialistList, unAssignCoordinator } from '../../../utils/ApiClient';
import useGlobalState from '../../../context/useGlobalState';
import { ApiPRTCaseDetails } from '../../../interfaces/ApiTypes';
import InlineError from '../../common/Error/InlineError';
import { Backdrop,Box,Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface CaseDetailsProps {
    activeCaseInfo: ApiPRTCaseDetails | undefined;
    caseStatus: string;
    setCaseStatus: (status: string) => void;
    caseLoading: boolean;
    providers?: any;
    specialist: string;
    casesTypeName: string;
    setCasesTypeName: (caseType: string) => void;
    setCaseLoading: (loading: boolean) => void;
    setCaseReopen?: (open: boolean) => void;
    setIsUnassign?: (assign: boolean) => void;
    loader: boolean;
    onCaseReOpen?: (id: number) => void;
    onGetCaseData?: (id:number) => void;
}

const CaseDetails: React.FC<CaseDetailsProps> = (props) => {
    const [status, setStatus] = useState<string>("");
    const [specialistName, setSpecialistName] = useState("");
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [error, setError] = useState<string>("")
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [specialistList, setSpecialistList] = useState<[]>([])
    const [isDialogButtonDisabled, setIsDialogButtonDisabled] = useState<boolean>(false)
    const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
    const isManager = userTypeName === userType.PRT_MANAGER
    const { globalErrorHandler } = useGlobalState()
    const effectRef = useRef(false)
    const unassignedButtonVisible = isManager && PRTManagerCaseType.ALL_CASES.label === props.casesTypeName && props.activeCaseInfo?.coordinatorOnCase;
    const isOldCase = props.activeCaseInfo && props.activeCaseInfo.eapxLastModifiedDays >= 60;
    const showReopenButton = props.casesTypeName === PRTUserCasesType.CLOSED.label && props.activeCaseInfo && props.activeCaseInfo.eapxLastModifiedDays <= 60;

    const fetchSpecialist = async (): Promise<void> => {
        try {
            const response = await getPRTSpecialistList()
            setSpecialistList(response.data.data.prtSpecialists)
        } catch (error) {
            setSpecialistList([])
            globalErrorHandler(error)
        }
    }

    useEffect(() => {
        setSpecialistName(props?.specialist)
    }, [props?.specialist])

    useEffect(() => {
        setStatus(props?.caseStatus)
    }, [props?.caseStatus])

    useEffect(() => {
        if (effectRef.current === false && userTypeName === userType.PRT_USER) {
            fetchSpecialist()
        }
        return () => { effectRef.current = true }
    }, [])

    useEffect(() => {
        if (successMessage) {
            props.setCaseStatus(status);
            setTimeout(() => {
                setSuccessMessage("")
                if (props?.setIsUnassign) props.setIsUnassign(true)
                if (status === EAPXCaseStatusEnum.RE_OPENED && props.casesTypeName === PRTUserCasesType.CLOSED.label) {
                    props.setCasesTypeName(PRTUserCasesType.MY_CASES.label)
                    if (props?.setCaseReopen) { props.setCaseReopen(true) }
                    props.setCaseLoading(true)
                }
            }, 5000)
        }
        if (error) {
            setTimeout(() => {
                setError("")
            }, 3000)
        }
    }, [successMessage, error])

    const handleCancel = () => {
        setOpenDialog(false)
    }

    const handleUnAssign = async (): Promise<void> => {
        setIsDialogButtonDisabled(true)
        try {
            const response = await unAssignCoordinator(props?.activeCaseInfo?.fileNumber || 0);
            setSuccessMessage(response.data.message)
            if(props.onGetCaseData){
             props.onGetCaseData(props?.activeCaseInfo?.fileNumber || 0)
            }
            setIsDialogButtonDisabled(false)
            handleCancel()
        } catch (error) {
            globalErrorHandler(error)
            setIsDialogButtonDisabled(false)
            handleCancel()
        }
    }

    const handleReopenCase = (fileNo: number) => {
        if (props?.onCaseReOpen) props.onCaseReOpen(fileNo)
    };
    return (
        <>{
            (props.activeCaseInfo && !props.caseLoading) && <React.Fragment>
                <div className='case-sub-section'>
                    <div className='case-staus-section'>
                        <div className='case-status-content'>
                            <span>EAPX Case Id: {props?.activeCaseInfo?.id}</span>
                            <span>EAPX File Number: {props?.activeCaseInfo?.fileNumber}</span>
                            <span>Case status: {props?.caseStatus}</span>
                            <span>ICT Case Manager: {props?.activeCaseInfo?.fileCaseManagerName !== null ?props?.activeCaseInfo?.fileCaseManagerName: "N/A"}</span>
                            <span>Last modified: {getFormatDateString(props?.activeCaseInfo?.lastModified).formattedDate}</span>
                            {
                                isManager && <div className="details">
                                    <span>Date Opened: {getFormatDateString(props?.activeCaseInfo?.dateOpened).formattedDate}</span>
                                    <span>Coordinator on Case: {props?.activeCaseInfo?.coordinatorOnCase}</span>
                                    <span>Assigned Specialist: {props?.activeCaseInfo?.assignedSpecialist}</span>
                                </div>
                            }
                        </div>
                        {
                            unassignedButtonVisible ? <div className='btn-section'><button className='light-green-button' onClick={() => setOpenDialog(true)}>{"UN-ASSIGN COORDINATOR"}</button> </div>
                                : <> {
                                    !isManager && <div className='case-status-content'>
                                    {showReopenButton ?<div className='btn-section'><button className='light-green-button'  onClick={() => handleReopenCase(props?.activeCaseInfo?.fileNumber || 0)} style={{
                                        width: '50%',
                                        borderRadius:"20px",
                                        marginBottom: '4px',
                                        height:"42px"
                                    }}> {"RE-OPEN CASE"}</button> </div> :<Box sx={{ textAlign: 'end' }}>
                                    <Button
                                       aria-controls="assigned-specialist-menu"
                                       aria-haspopup="true"
                                       className='case-status-button'
                                       style={{ 
                                          width: props?.casesTypeName === PRTUserCasesType.CLOSED.label ? '50%' :'70%',
                                          borderRadius: '7px',
                                          height:"42px",
                                          justifyContent: 'space-between',
                                          textAlign: 'end',
                                          backgroundColor: 'white',
                                          color: '#000000',
                                      }}
                                      sx={{ textTransform: 'none' }}
                                      variant='contained'
                                      color='info'
                                      >
                                       {props?.caseStatus}
                                      </Button>
                                  </Box>}
                                        <CustomDropdownMenu
                                            options={specialistList}
                                            name={"Assigned Specialist"}
                                            stateChange={setSpecialistName}
                                            specialist={props?.specialist}
                                            state={specialistName}
                                            showButtons={true}
                                            fileNo={props?.activeCaseInfo?.fileNumber}
                                            showPopUp={true}
                                            setSuccessMessage={setSuccessMessage}
                                            casesTypeName={props?.casesTypeName}
                                            setError={setError}
                                            isOldCase={isOldCase}
                                            width={props?.casesTypeName === PRTUserCasesType.CLOSED.label ? "50%":"70%"}
                                        />
                                    </div>
                                }
                                </>
                        }
                    </div>
                    {
                        successMessage && <span className='success success-msg-section'>{successMessage}</span>
                    }
                    {
                        error && <InlineError errorMessage={error} />
                    }
                </div>
                <div className='case-sub-section'>
                    <span>Gender : {props?.activeCaseInfo?.patientInformation?.gender ?? "-"}</span>
                    <span>Age : {props?.activeCaseInfo?.patientInformation.age ?? "-"}</span>
                    <span>State : {props?.activeCaseInfo?.patientInformation?.state ?? "-"}</span>
                    <span>Zipcode : {props?.activeCaseInfo?.patientInformation?.zipcode ?? "-"}</span>
                </div>
                <div className='case-sub-section'>
                    <span>Client Company : {props?.activeCaseInfo?.companyName ?? "-"} </span>
                    <span>Presenting condition : {props?.activeCaseInfo?.presentingCondition.name ?? "-"} </span>
                    <span>Prefered modality : {props?.activeCaseInfo?.preferredModality ?? "-"} </span>
                    <span>Distance willing to travel : {props?.activeCaseInfo?.distanceWillingToTravel ?? "-"} </span>
                    <span>Provider preferences: {props?.activeCaseInfo?.providerPreference ?? "-"} </span>
                    <span>Appointment date preference: {getAppointString(props?.activeCaseInfo?.appointmentDatePreference)} </span>
                </div>
            </React.Fragment>
        }
            <ConfirmDialogBox
                title={unAssignDialog.title}
                content={unAssignDialog.content}
                confirmLabel={unAssignDialog.confirmLabel}
                onConfirm={handleUnAssign}
                handleClose={handleCancel}
                openDialogBox={openDialog}
                buttonDisabled={isDialogButtonDisabled} />
            <Backdrop open={props?.loader} style={{ zIndex: 1301 }}>
                <div className='backdrop-loader-reopen'>
                    <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.12) ' }} />
                    <span>{"RE-OPENING CASE"}</span>
                </div>
            </Backdrop>
        </>
    )
}

export default React.memo(CaseDetails)