import { useEffect, useRef, useState } from "react"
import SearchBox from "../../common/SearchBox/SearchBox"
import { PRT_Specialist } from "../../../utils/constants"
import { addPRTSpecialist, getPRTSpecialistList, editPRTSpecialist, deletePRTSpecialist, searchPRTSpecialistList } from "../../../utils/ApiClient"
import useGlobalState from "../../../context/useGlobalState"
import Loader from "../../common/Loader/Loader"
import DataList from "../../common/PRT-Settings/DataList"
import { ApiDatalistItem } from "../../../interfaces/ApiTypes"
import InlineError from "../../common/Error/InlineError"

interface Specialist {
  id : number;
  fullName : string
}

const Specialists = () => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [error, setError] = useState<boolean>(false)
  const [specialist, setSpecialist] = useState<ApiDatalistItem[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)
  const [searchTermAvailable, setSearchTermAvailable] = useState<boolean>(false)
  const [addRow, setAddRow] = useState<Specialist[]>([])
  const effectRef = useRef(false)
  const [noSpecialist, setNoSpecialist] = useState<boolean>(false)
  const { globalErrorHandler } = useGlobalState()

  const fetchSpecialistsData = async (apiCall: () => Promise<any>, search: boolean = false): Promise<void> => {
    setLoading(true);
    try {
      const response = await apiCall();
      const specialists = response.data.data.prtSpecialists;

      if (specialists.length === 0) {
        setNoSpecialist(true);
        setSpecialist([])
      } else {
        setNoSpecialist(false);
        setSpecialist(specialists);
      }
      if (search) setSearchTermAvailable(true);
    } catch (error) {
      globalErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSpecialists = async (): Promise<void> => {
    await fetchSpecialistsData(getPRTSpecialistList);
  }

  const addSpecialist = async (id: number): Promise<void> => {
    const specialist = addRow.find((row: Specialist) => row.id === id);
    const fullName = specialist ? specialist.fullName : null;
    if (!fullName) {
      setError(true)
    } else {
      setError(false)
      setLoading(true)
      try {
        const response = await addPRTSpecialist(fullName)
        setAddRow([])
        setSuccessMessage(response.data.message)
        fetchSpecialists()
        setLoading(false)
      } catch (error) {
        setLoading(false)
        globalErrorHandler(error)
      }
    }
  }

  const addSpecialistRow = () => {
    const specialist = {
      id: Math.random() * 100,
      fullName: ""
    };
    setAddRow((prevState: Specialist[]) => [...prevState, specialist]);
  }

  const handleSearchClick = async (): Promise<void> => {
    if (searchTermAvailable) {
      await fetchSpecialists();
      setSearchTerm("")
      setSearchTermAvailable(false);
      return;
    } else {
      if (!searchTerm) {
        setError(true)
        return
      }
      await fetchSpecialistsData(() => searchPRTSpecialistList(searchTerm), true);
      setSearchTermAvailable(true)
    }
  }

  const handleInputChange = (id: number, newValue: string) => {
    setAddRow((prevState: Specialist[]) =>
      prevState.map(row =>
        row.id === id ? { ...row, fullName: newValue } : row
      )
    );
  };

  const handleDeleteRow = (id: number) => {
    setAddRow((prevState: Specialist[]) => prevState.filter(row => row.id !== id));
  };

  useEffect(() => {
    if (effectRef.current === false) {
      fetchSpecialists()
    }
    return () => { effectRef.current = true }
  }, [])

  useEffect(() => {
    if (error || successMessage) {
      setTimeout(() => {
        setError(false)
        setSuccessMessage("")
      }, 5000)
    }
  }, [error, successMessage])

  return (
    <div>
      {
        loading ? <div className="loader-box"><Loader /></div> : <div className="setting-component">
          <div className="setting-grid">
            <div className="setting-info">
              <span className="setting-title">{PRT_Specialist.title}</span>
              <span className="setting-desciption">{PRT_Specialist.description}</span>
            </div>
            <div className="searc-box-section">
              <SearchBox value={searchTerm} setValue={setSearchTerm} onClick={handleSearchClick} searchTermAvailable={searchTermAvailable} />
            </div>
          </div>
          <div className="add-section">
            <span className="input-area">{"Full Name"}</span>
            <button className="add-button" onClick={addSpecialistRow}>{"+ Add Specialist"}</button>
          </div>
          {addRow.map((row: any) => (
            <div key={row.id} className="common-action-bar">
              <input
                type="text"
                className="input-box"
                value={row.fullName}
                onChange={(e) => handleInputChange(row.id, e.target.value)}
              />
              <div className="button-box">
                <span onClick={() => handleDeleteRow(row.id)} className="line-button">Delete</span>
                <button onClick={() => addSpecialist(row.id)} className="green-line-button">{"ADD"}</button>
              </div>
            </div>
          ))}
          {error && <InlineError errorMessage="Please enter Specialist name" />}
          {successMessage && <span className="success">{successMessage}</span>}
          {noSpecialist && <span className="no-data">{"No specialists found"}</span>}
          <DataList
            list={specialist}
            hideButtons={false}
            deleteAPI={deletePRTSpecialist}
            editAPI={editPRTSpecialist}
            fetchAPI={fetchSpecialists}
            setSuccessMessage={setSuccessMessage}
            setLoading={setLoading} />
        </div>
      }
    </div>
  )
}

export default Specialists