import React, { useState, useEffect } from 'react'
import DeleteBin from '../../assests/icons/bin.png'
import { addProviderOutReach, deleteProviderOutReach, editProviderOutReach } from '../../../utils/ApiClient';
import CustomSelectWithButtons from '../../common/DropdownMenu/DropdownWithConfirmationBar';
import { ProviderStatus, providerAssignDialog, deleteProviderDialog, assigningProviderDialog, storageKeys, userType, PRTUserCasesType, SideBarTabItemType1, EAPXCaseStatusEnum } from '../../../utils/constants';
import ConfirmationDialogBox from '../../common/DialogBox/ConfirmDialogBox'
import useGlobalState from '../../../context/useGlobalState';
import { validateRequiredFields, extractDateTime } from '../../../utils/helperFunctions';
import LoadingIcon from '../../assests/icons/loader.png';
import { ProvidersType, Provider } from './PRTDashboardHome';
import './Provider.scss'
import { ApiEAPXCase } from '../../../interfaces/ApiTypes';
import InlineError from '../../common/Error/InlineError';

interface AdditionalProviderProps {
  provider: Provider;
  providerNo: number;
  activeCaseUUID: string;
  setProviders: React.Dispatch<React.SetStateAction<ProvidersType>>;
  providers: ProvidersType;
  caseStatus: string;
  setCasesTypeName: (caseTpe: string) => void;
  setNoData: (data: boolean) => void;
  setCases: (cases: ApiEAPXCase[]) => void;
  casesTypeName: string;
  onAssignProvider?: (providerId: number) => Promise<void>;
  successMessage?: string;
  isOldCase?: boolean;
}

const ProviderComponent: React.FC<AdditionalProviderProps> = ({ provider, providerNo, activeCaseUUID, setProviders, caseStatus, casesTypeName, onAssignProvider, successMessage, isOldCase }) => {
  const [providerInfo, setProviderInfo] = useState<Provider>({
    eapx_case: "",
    eapx_file_number: undefined,
    id: 0,
    provider_detail: "",
    status: "",
    notes: "",
    assignedDate: null,
    providerStatus: ProviderStatus.REQUEST_SENT.value,
    providerStatusDisplay: ProviderStatus.REQUEST_SENT.label,
    file_number: 0
  })
  const [errors, setErrors] = useState<string>("");
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [openAssignProvider, setOpenAssignProvider] = useState<boolean>(false)
  const [status, setStatus] = useState<string | null | undefined>(null)
  const [isDialogButtonDisabled, setIsDialogButtonDisabled] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(false)
  const { globalErrorHandler } = useGlobalState();
  const disabled = (casesTypeName === PRTUserCasesType.CLOSED.label)
  const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
  const isManager = userTypeName === userType.PRT_MANAGER
  const disabledText = casesTypeName === PRTUserCasesType.CLOSED.label && caseStatus === EAPXCaseStatusEnum.ACCEPTED && provider?.assignedDate

  useEffect(() => {
    setProviderInfo(provider)
  }, [provider])

  useEffect(() => {
    setStatus((provider?.providerStatusDisplay ?? null) as string | null)
  }, [provider?.providerStatusDisplay])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setProviderInfo((prev: Provider) => ({
      ...prev,
      [name]: value,
    }));
  }

  const removeProvider = (index: number) => {
    setProviders((prevProviders: ProvidersType) => {
      let updatedProviders = { ...prevProviders };
      updatedProviders = [
        ...prevProviders.slice(0, index),
        ...prevProviders.slice(index + 1)
      ];
      return updatedProviders;
    });
  };

  const saveProviderDetails = async (providerIndex: number): Promise<void> => {
    const result = validateRequiredFields(providerInfo)
    if (!result.valid) {
      setErrors(result?.message ?? "");
    } else {
      setErrors("")
      const matchedValue = Object.values(ProviderStatus).find(item => item.label === status);
      const data = {
        eapx_file_number: providerInfo.eapx_file_number,
        eapx_case: activeCaseUUID,
        provider_detail: providerInfo.provider_detail,
        notes: providerInfo.notes,
        provider_status: matchedValue?.value
      };
      try {
        const response = await addProviderOutReach(data);
        setErrors("")
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
          const newProvider = { ...providerInfo, status: "Saved" };
          setProviderInfo(newProvider);
          setProviders((prev: ProvidersType) =>
            prev.map((provider: Provider, index: number) =>
              index === providerIndex ? newProvider : provider
            )
          );
        }, 1000)
        setTimeout(() => {
          const newProvider = { ...providerInfo, id: response.data.data.id, provider_status: matchedValue, provider_status_display: status, status: "Save" };
          setProviderInfo(newProvider);
          setProviders((prev: ProvidersType) =>
            prev.map((provider: Provider, index: number) =>
              index === providerIndex ? newProvider : provider
            )
          )
        }, 2000)
      } catch (error) {
        setLoader(false);
        globalErrorHandler(error);
      }
    }
  };

  const changeButtonName = (data: Provider) => {
    setLoader(false)
    setTimeout(() => {
      const updatedData = { ...providerInfo, ...data, status: "Save" };
      setProviderInfo(updatedData);
      setProviders((prev: ProvidersType) =>
        prev.map((provider: Provider, index: number) =>
          provider.id === providerInfo.id ? updatedData : provider
        )
      )
    }, 1000)
  }

  const updateProvider = async (): Promise<void> => {
    const matchedValue = Object.values(ProviderStatus).find((item: SideBarTabItemType1) => item.label === status);
    const { providerStatus, providerStatusDisplay, ...remainingProviderInfo } = providerInfo;
    const data = {  ...remainingProviderInfo, provider_status: matchedValue?.value, provider_status_display: status };
    delete data.status;
    try {
      await editProviderOutReach(providerInfo.id, data);
      setLoader(true)
      const updatedData = { ...providerInfo, ...data, status: "Saved" };
      setTimeout(() => {
        setProviderInfo(updatedData);
        setProviders((prev: ProvidersType) =>
          prev.map((provider: Provider, index: number) =>
            provider.id === providerInfo.id ? updatedData : provider
          )
        );
      }, 1000)
      setTimeout(() => {
        changeButtonName({ 
          ...data, 
          providerStatus: matchedValue?.value, 
          providerStatusDisplay: status 
        })
      }, 1000)
    } catch (error) {
      globalErrorHandler(error);
      setProviderInfo({ ...provider });
    }
  };

  const removeProviderOutreach = async (providerId: number): Promise<void> => {
    setIsDialogButtonDisabled(true)
    try {
      await deleteProviderOutReach(providerId);
      setProviders((prev: ProvidersType) =>
        prev.filter((provider: Provider) => provider.id !== providerId)
      );
      setIsDialogButtonDisabled(false)
      handleCancelButtonPressed()
    } catch (error) {
      setIsDialogButtonDisabled(false)
      handleCancelButtonPressed()
      globalErrorHandler(error);
    }
  };

  const handlePopUp = (index: number) => {
    if (providerInfo.id !== 0) {
      setOpenDeleteDialog(true)
    } else {
      removeProvider(index)
    }
  }

  const handleCancelButtonPressed = () => {
    if (openDeleteDialog) {
      setOpenDeleteDialog(false)
    }
    if (openDialogBox) {
      setOpenDialogBox(false)
    }
    if (openAssignProvider) {
      setOpenAssignProvider(false)
    }
  }

  const handleAssignProvider = async (id: number): Promise<void> => {
    setIsDialogButtonDisabled(true);
    handleCancelButtonPressed();
    try {
      if (onAssignProvider) {
        await onAssignProvider(id);
      }
    } catch (error) {
      globalErrorHandler(error);
    } finally {
      setIsDialogButtonDisabled(false);
    }
  };

  const handleProviderStatus = (provider: Provider | null): void => {
    const acceptedStatus = ProviderStatus.PROVIDER_ACCEPTED.value;
    if (!provider) return;
    provider.providerStatus === acceptedStatus
      ? setOpenAssignProvider(true)
      : setOpenDialogBox(true);
  };

  const updateProvidersState = (newProvider: Provider): void => {
    setProviderInfo(newProvider);
    setProviders((prev: ProvidersType) =>
      prev.map((provider: Provider, index: number) =>
        index === providerNo ? newProvider : provider
      )
    );
  };

  const handleAssign = async (): Promise<void> => {
    const acceptedStatus = ProviderStatus.PROVIDER_ACCEPTED.value;
    if (providerInfo.providerStatus === acceptedStatus) {
      setOpenAssignProvider(true);
      return;
    }
    try {
      if (providerInfo.id === 0) {
        const matchedValue = Object.values(ProviderStatus).find(
          (item) => item.label === status
        );
        const data = {
          eapx_file_number: providerInfo.eapx_file_number,
          eapx_case: activeCaseUUID,
          provider_detail: providerInfo.provider_detail,
          notes: providerInfo.notes,
          provider_status: matchedValue?.value,
        };
        const response = await addProviderOutReach(data);
        const newProvider: Provider = {
          ...providerInfo,
          id: response.data.data.id,
          providerStatus: matchedValue,
          providerStatusDisplay: status,
          status: "Save",
        };
        updateProvidersState(newProvider);
        handleProviderStatus(newProvider);
      }
      else {
        setOpenDialogBox(true)
      }
    } catch (error) {
      globalErrorHandler(error);
    }
  }

  return (
    <div className='additional-provider-component'>
      <div className='provider-action-header'>
        <span className='provider-title'>Provider #{providerNo + 1}</span>
        {
          successMessage ? <span className='success message-block'>{successMessage}</span> :
            !isOldCase ? <div>
              {
                caseStatus === EAPXCaseStatusEnum.RE_OPENED && provider?.assignedDate ?
                  <span className='success message-block'> PROVIDER ASSIGNED - {extractDateTime(provider?.assignedDate)}</span> :
                  <div className='buttons-section'>
                    {
                      casesTypeName === PRTUserCasesType.CLOSED.label && caseStatus === EAPXCaseStatusEnum.ACCEPTED && provider?.assignedDate ?
                        <span className={disabledText ? 'disbled-block success message-block' : 'success message-block'}> PROVIDER ASSIGNED - {extractDateTime(provider?.assignedDate)}</span> :
                        <div className='dropdown-section'>
                          <CustomSelectWithButtons
                            options={ProviderStatus}
                            name='provider-status'
                            stateChange={setStatus}
                            state={status}
                            showButtons={false}
                            fileNo={providerInfo.file_number}
                            showPopUp={true}
                            casesTypeName={casesTypeName}
                            isOldCase={isOldCase}
                            width={"100%"}/>
                        </div>
                    }
                    {
                      !(casesTypeName === PRTUserCasesType.CLOSED.label || isManager) && <div className="buttons">
                        <img src={DeleteBin} alt='delete' className='delete-icon' onClick={() => handlePopUp(providerNo)} />
                        <button
                          className='save-button'
                          onClick={() => providerInfo.id > 0 ? updateProvider() : saveProviderDetails(providerNo)}>
                          {loader ? <img src={LoadingIcon} alt='loading' /> : <>{provider.status === "Saved" ? "SAVED!" : "SAVE"}</>}
                        </button>
                        <button
                          className='assign-button'
                          onClick={handleAssign}>
                          {"Assign"}
                        </button>
                      </div>
                    }
                  </div>
              }
            </div> : caseStatus === EAPXCaseStatusEnum.ACCEPTED && provider?.assignedDate &&
            <span className='success message-block'> PROVIDER ASSIGNED - {extractDateTime(provider?.assignedDate)}</span>
        }
      </div>
      <div className='fields'>
        <textarea rows={8} className={disabled || isManager || provider?.assignedDate ? 'input-box-disabled' : 'input-box'} placeholder='Notes' name='notes' value={providerInfo.notes} onChange={handleChange} disabled={disabled || isManager || (provider?.assignedDate ? true : false)} />
      </div>
      <div>{errors && <InlineError errorMessage={errors} />}
      </div>
      <ConfirmationDialogBox
        title={providerAssignDialog.title}
        content={providerAssignDialog.content}
        onConfirm={() => handleAssignProvider(providerInfo.id)}
        handleClose={handleCancelButtonPressed}
        confirmLabel={providerAssignDialog.confirmLabel}
        openDialogBox={openDialogBox}
        buttonDisabled={isDialogButtonDisabled} />
      <ConfirmationDialogBox
        title={deleteProviderDialog.title}
        content={deleteProviderDialog.content}
        onConfirm={() => removeProviderOutreach(providerInfo.id)}
        handleClose={handleCancelButtonPressed}
        confirmLabel={deleteProviderDialog.confirmLabel}
        openDialogBox={openDeleteDialog}
        buttonDisabled={isDialogButtonDisabled} />
      <ConfirmationDialogBox
        title={assigningProviderDialog.title}
        content={assigningProviderDialog.content}
        onConfirm={() => handleAssignProvider(providerInfo.id)}
        handleClose={handleCancelButtonPressed}
        confirmLabel={assigningProviderDialog.confirmLabel}
        openDialogBox={openAssignProvider}
        buttonDisabled={isDialogButtonDisabled} />
    </div>
  )
}

export default React.memo(ProviderComponent)